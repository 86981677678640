import React, { useContext, useEffect, useState } from 'react'
import dlt from "../../Assets/Icons/trash.png";
import MyContext from '../../MyContext';
import Order from '../../../API/API_SERVISES/Order';
import AlertBox from '../../Component/AlertBox/AlertBox';

const FavouritepageMobile = () => {

  const { imageBaseUrl, wishList, setWishList, fetchDataWish } = useContext(MyContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [getdeleteId, setDeleteId] = useState(null);

  const [alert, setAlert] = useState(false);
  const alertbox = (id) => {
    setAlert(!alert)
  }

  const DeleteAccountConfirm = async (id) => {

    try {
      const val = await Order.deleteDeleteFavouriteItemList(id);
      if (Boolean(val) === true) {
        setDeleteId(null);
        fetchDataWish();
        alert("Delete data successfully", "Ok");
      } else {
        alert("Error Deleting Data");
      }

    } catch (error) {
      console.error("Error deleting account:", error);
    }
    // fetchData();
  };


  return (
    <div className="my-fav-top" style={{ height: "80vh" }}>
      <div style={{ height: "40px", marginBottom: "10px", paddingBottom: "10px", paddingTop: "10px" }} className='title'>My favorite</div>
      {wishList.map(product => (
        <div className="favourite-box">
          <div className="review-box-image">
            <img className="favourite-img "
              src={`${imageBaseUrl}${product.itemImgId}`}
              alt=""
            />
          </div>
          <div className="review-box-detail">
            <div className="review-box-detail-line-1">
              <div className="review-box-name">
                {product.itemName}
              </div>
              <div className="review-box-section" onClick={() => DeleteAccountConfirm(product.id)}>
                {/* <i className="fa-solid fa-trash"></i> */}
                <img className="fa-trash" src={dlt} alt="" />
              </div>
            </div>


          </div>
        </div>
      ))}
    </div>
  )
}

export default FavouritepageMobile