import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
import coupon from "../../Assets/Icons/discount.png"
import Modal from "../../Component/Modal/Modal";
import AlertBox from "../../Component/AlertBox/AlertBox";
import AddCouponModal from "../../Component/Modal/AddCouponModal";
import Order from "../../../API/API_SERVISES/Order";
import dlt from "../../Assets/Icons/trash.png"
import MyContext from "../../MyContext";
import axios from 'axios';
import swal from 'sweetalert';
import AlertSuccess from "../../Component/AlertBox/AlertSuccess";
import { Link, useLocation } from "react-router-dom";
import StateInfoApi from "../../../API/API_SERVISES/StateInfo";
import CountryApi from "../../../API/API_SERVISES/Country";
import AddAddress from "../../../API/API_SERVISES/AddAddress";
import { useNavigate } from 'react-router-dom';
import WhatsAppButton from "../../Component/Modal/WhatsappButton";

const ShippingAddress = (props) => {
    // const [modal, setModal] = useState(false);
    // const [alert, setAlert] = useState(false);
    const { companyData } = useContext(MyContext);
    const [addcoupon, setAddCoupon] = useState(false);
    const location = useLocation()
    const [pathname, setpathname] = useState(null)
    // const [sweetAlert, setSweetAlert] = useState(false);
    // const alertbox = () => {
    //     setAlert(!alert)
    // }
    console.log(location.pathname)
    const navigationBar = [
        {
            name: 'Login/Signup',
            disable: true,
            navigationroute: "/"
        },
        {
            name: 'Shipping Address',
            disable: false,
            navigationroute: "/ShippingAddress"
        },
        {
            name: 'Payment',
            disable: true,
            navigationroute: "/PaymentMethods"
        },
        {
            name: 'Summary',
            disable: true,
            navigationroute: "/"
        }
    ]

    const [EditData, setEditData] = useState();
    const [addresses, setAddAddresses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedAddress, setSelectedAddress] = useState(null);

    const navigate = useNavigate();

    // const handleCheckboxChange = (addressIndex) => {
    //     setSelectedAddress(addressIndex);
    // };

    const { DefaultAddressChange,
        setDefaultAddressChange } = useContext(MyContext)

    const handleCheckboxChange = async (addressIndex, id) => {
        var getaddress = await AddAddress.getAddressById(id, myAccountID);
        if (getaddress.isDefault == false) {
            const updatedValues = {
                ...getaddress,
                "isDefault": true,
            };
            await AddAddress.updateAddress(updatedValues, myAccountID);
        }
        setDefaultAddressChange(!DefaultAddressChange)
        setSelectedAddress(id);
        alert("Updated Default Address");
        fetchAddress()
    };

    const OpenAddCoupon = () => {
        setAddCoupon(!addcoupon)
    }
    const { myaccountmodal, setTotalQuantity, modal, setLoginPanel, totalQuantity, InputValues, imageBaseUrl,
        fetchDataWish, currentUser, wishList, setWishList, totalPrice, setTotalPrice, myAccount, setMyAccount, } = useContext(MyContext)

    //****************************************************************** */
    const [isModalOpenAddAddress, setIsModalOpenAddAddress] = useState(false);
    const [showSelectAddress, setShowSelectAddress] = useState(true);

    const handleOpenModalAddAddress = () => {
        setIsModalOpenAddAddress(true);
        setShowSelectAddress(false);
        setEditData("");
    };

    const handleGoBack = () => {
        setIsModalOpenAddAddress(false);
        setShowSelectAddress(true); // Show Select Address section when going back
    };

    //************************************************************ */

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const signin = () => {
        setLoginPanel(!modal)
    }
    const {
        item
    } = props;

    const [activeLink, setActiveLink] = useState("/");
    const handleLinkClick = (link) => {
        setActiveLink(link);
    };

    // const [totalPrice, setTotalPrice] = useState(0);
    // const [taxtotalPrice, setTaxTotalPrice] = useState(0);
    // const [finalPrice, setFinalPrice] = useState(0);
    // const [productPrice, setProductPrice] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const itemsPerPage = 3; // Number of items to show per page
    const itemsPerPage2 = 1; // Number of items to show per page

    //*****************************ADD Address************************************* */
    const [myAccountID, setMyAccountID] = useState();

    useEffect(() => {
        var loginDataString = localStorage.getItem("accountLogin");
        var loginData = JSON.parse(loginDataString);
        // console.log(loginData.d1Id);
        setMyAccountID(loginData.d1Id);
    }, [myAccountID]);

    const [inputValues, setInputValues] = useState({
        accountId: "",
        name: "",
        mobile: "",
        email: "",
        pincode: "",
        state: "",
        country: "",
        address: "",
        locality: "",
        city: "",
        typeOfAddress: 0,
        isDefault: "",
        isActive: true,
    });

    // useEffect(() => {
    //     console.log(EditData);
    //     const fetchData = async () => {
    //         if (EditData) {
    //             try {
    //                 const data = await EditData; // Assuming props.editData is a Promise
    //                 setInputValues({
    //                     id: EditData.id,
    //                     accountId: 707,
    //                     name: EditData.name,
    //                     mobile: EditData.mobile,
    //                     pincode: EditData.pincode,
    //                     state: EditData.state,
    //                     address: EditData.address,
    //                     locality: EditData.locality,
    //                     city: EditData.city,
    //                     typeOfAddress: EditData.typeOfAddress,
    //                     isDefault: EditData.isDefault,
    //                     isActive: true,
    //                 });
    //             } catch (error) {
    //                 console.error("Error fetching data:", error);
    //             }
    //         } else if (EditData === "" || EditData === undefined) { // Use logical OR here
    //             setInputValues({
    //                 accountId: "",
    //                 name: "",
    //                 mobile: "",
    //                 pincode: "",
    //                 state: "",
    //                 address: "",
    //                 locality: "",
    //                 city: "",
    //                 typeOfAddress: 0,
    //                 isDefault: "",
    //                 isActive: true,
    //             });
    //         }
    //     };

    //     fetchData();
    // }, [EditData]);

    useEffect(() => {
        console.log(EditData);
        const fetchData = () => {
            if (EditData) {
                setInputValues({
                    id: EditData.id,
                    accountId: 707,
                    name: EditData.name,
                    mobile: EditData.mobile,
                    pincode: EditData.pincode,
                    state: EditData.state,
                    country: EditData.countryCode,
                    address: EditData.address,
                    locality: EditData.locality,
                    city: EditData.city,
                    typeOfAddress: EditData.typeOfAddress,
                    isDefault: EditData.isDefault,
                    isActive: true,
                });
            } else {
                setInputValues({
                    accountId: "",
                    name: "",
                    mobile: "",
                    pincode: "",
                    state: "",
                    address: "",
                    locality: "",
                    city: "",
                    typeOfAddress: 0,
                    isDefault: "",
                    isActive: true,
                });
            }
        };

        fetchData();
    }, [EditData]);

    const [errorMessages, setErrorMessages] = useState({
        name: "",
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };

    const handleInputChange1 = (value) => {
        setInputValues({
            ...inputValues,
            typeOfAddress: Number(value),
        });
    };

    const handleInputChange2 = (e) => {
        const checked = e.target.checked;
        setInputValues({
            ...inputValues,
            isDefault: checked,
        });
    };


    // const fetchAddress1 = () => {
    //     const userLogin = localStorage.getItem("accountLogin");
    //     const userArray = userLogin ? JSON.parse(userLogin) : null;

    //     if (userArray) {
    //         navigate('/PaymentMethods');
    //     }
    //     // else {
    //     //   setMyAccount(!myAccount);
    //     //   document.body.style.overflow = myAccount ? "auto" : "hidden";
    //     //   signupshow();
    //     // }
    // };

    const [stateInfo, setStateInfo] = useState();
    //useEffect(() => {
    //    const fetchData = async () => {
    //        try {
    //            const data = await StateInfoApi.GetAllState();
    //            setStateInfo(data);
    //        } catch (error) {
    //            console.error("Error fetching data:", error);
    //        }
    //    };
    //    fetchData();
    //}, []);

    // const [country, setCountry] = useState();
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //         const data = await StateInfoApi.GetAllState();

    //       } catch (error) {
    //         console.error("Error fetching data:", error);
    //       }
    //     };
    //     fetchData();
    // }, []);

    const [country, setCountry] = useState([
        {
            id: 1001,
            name: "Afghanistan",
        },
        {
            id: 1002,
            name: "Albania",
        },
        {
            id: 1003,
            name: "Algeria",
        },
        {
            id: 1004,
            name: "American Samoa",
        },
        {
            id: 1005,
            name: "Andorra",
        },
        {
            id: 1006,
            name: "Angola",
        },
        {
            id: 1007,
            name: "Anguilla",
        },
        {
            id: 1008,
            name: "Antarctica",
        },
        {
            id: 1009,
            name: "Antigua and Barbuda",
        },
        {
            id: 1010,
            name: "Argentina",
        },
        {
            id: 1011,
            name: "Armenia",
        },
        {
            id: 1012,
            name: "Aruba",
        },
        {
            id: 1013,
            name: "Australia",
        },
        {
            id: 1014,
            name: "Austria",
        },
        {
            id: 1015,
            name: "Azerbaijan",
        },
        {
            id: 1016,
            name: "Bahrain",
        },
        {
            id: 1017,
            name: "Bangladesh",
        },
        {
            id: 1018,
            name: "Barbados",
        },
        {
            id: 1019,
            name: "Belarus",
        },
        {
            id: 1020,
            name: "Belgium",
        },
        {
            id: 1021,
            name: "Belize",
        },
        {
            id: 1022,
            name: "Benin",
        },
        {
            id: 1023,
            name: "Bermuda",
        },
        {
            id: 1024,
            name: "Bhutan",
        },
        {
            id: 1025,
            name: "Bolivia",
        },
        {
            id: 1026,
            name: "Bosnia and Herzegovina",
        },
        {
            id: 1027,
            name: "Botswana",
        },
        {
            id: 1028,
            name: "Bouvet Island",
        },
        {
            id: 1029,
            name: "Brazil",
        },
        {
            id: 1030,
            name: "British Indian Ocean Territory",
        },
        {
            id: 1031,
            name: "Virgin Islands, British",
        },
        {
            id: 1032,
            name: "Brunei Darussalam",
        },
        {
            id: 1033,
            name: "Bulgaria",
        },
        {
            id: 1034,
            name: "Burkina Faso",
        },
        {
            id: 1035,
            name: "Myanmar",
        },
        {
            id: 1036,
            name: "Burundi",
        },
        {
            id: 1037,
            name: "Cambodia",
        },
        {
            id: 1038,
            name: "Cameroon",
        },
        {
            id: 1039,
            name: "Canada",
        },
        {
            id: 1040,
            name: "Cape Verde",
        },
        {
            id: 1041,
            name: "Cayman Islands",
        },
        {
            id: 1042,
            name: "Central African Republic",
        },
        {
            id: 1043,
            name: "Chad",
        },
        {
            id: 1044,
            name: "Chile",
        },
        {
            id: 1045,
            name: "China",
        },
        {
            id: 1046,
            name: "Christmas Island",
        },
        {
            id: 1047,
            name: "Cocos Values(Keeling) Islands",
        },
        {
            id: 1048,
            name: "Colombia",
        },
        {
            id: 1049,
            name: "Comoros",
        },
        {
            id: 1050,
            name: "Congo, The Democratic Republic of the",
        },
        {
            id: 1051,
            name: "Congo",
        },
        {
            id: 1052,
            name: "Cook Islands",
        },
        {
            id: 1053,
            name: "Costa Rica",
        },
        {
            id: 1054,
            name: "Côte d`Ivoire",
        },
        {
            id: 1055,
            name: "Croatia",
        },
        {
            id: 1056,
            name: "Cuba",
        },
        {
            id: 1057,
            name: "Cyprus",
        },
        {
            id: 1058,
            name: "Czech Republic",
        },
        {
            id: 1059,
            name: "Denmark",
        },
        {
            id: 1060,
            name: "Djibouti",
        },
        {
            id: 1061,
            name: "Dominica",
        },
        {
            id: 1062,
            name: "Dominican Republic",
        },
        {
            id: 1063,
            name: "Timor-Leste",
        },
        {
            id: 1064,
            name: "Ecuador",
        },
        {
            id: 1065,
            name: "Egypt",
        },
        {
            id: 1066,
            name: "El Salvador",
        },
        {
            id: 1067,
            name: "Equatorial Guinea",
        },
        {
            id: 1068,
            name: "Eritrea",
        },
        {
            id: 1069,
            name: "Estonia",
        },
        {
            id: 1070,
            name: "Ethiopia",
        },
        {
            id: 1072,
            name: "Falkland Islands Values(Malvinas)",
        },
        {
            id: 1073,
            name: "Faroe Islands",
        },
        {
            id: 1074,
            name: "Fiji",
        },
        {
            id: 1075,
            name: "Finland",
        },
        {
            id: 1076,
            name: "France",
        },
        {
            id: 1077,
            name: "French Guiana",
        },
        {
            id: 1078,
            name: "French Polynesia",
        },
        {
            id: 1079,
            name: "French Southern Territories",
        },
        {
            id: 1080,
            name: "Gabon",
        },
        {
            id: 1081,
            name: "Georgia",
        },
        {
            id: 1082,
            name: "Germany",
        },
        {
            id: 1083,
            name: "Ghana",
        },
        {
            id: 1084,
            name: "Gibraltar",
        },
        {
            id: 1085,
            name: "Greece",
        },
        {
            id: 1086,
            name: "Greenland",
        },
        {
            id: 1087,
            name: "Grenada",
        },
        {
            id: 1088,
            name: "Guadeloupe",
        },
        {
            id: 1089,
            name: "Guam",
        },
        {
            id: 1090,
            name: "Guatemala",
        },
        {
            id: 1091,
            name: "Guinea",
        },
        {
            id: 1092,
            name: "Guinea-Bissau",
        },
        {
            id: 1093,
            name: "Guyana",
        },
        {
            id: 1094,
            name: "Haiti",
        },
        {
            id: 1095,
            name: "Heard Island and McDonald Islands",
        },
        {
            id: 1096,
            name: "Holy See Values(Vatican City State)",
        },
        {
            id: 1097,
            name: "Honduras",
        },
        {
            id: 1098,
            name: "Hong Kong",
        },
        {
            id: 1099,
            name: "Hungary",
        },
        {
            id: 1100,
            name: "Iceland",
        },
        {
            id: 1101,
            name: "India",
        },
        {
            id: 1102,
            name: "Indonesia",
        },
        {
            id: 1103,
            name: "Iran, Islamic Republic of",
        },
        {
            id: 1104,
            name: "Iraq",
        },
        {
            id: 1105,
            name: "Ireland",
        },
        {
            id: 1106,
            name: "Israel",
        },
        {
            id: 1107,
            name: "Italy",
        },
        {
            id: 1108,
            name: "Jamaica",
        },
        {
            id: 1109,
            name: "Japan",
        },
        {
            id: 1110,
            name: "Jordan",
        },
        {
            id: 1111,
            name: "Kazakhstan",
        },
        {
            id: 1112,
            name: "Kenya",
        },
        {
            id: 1113,
            name: "Kiribati",
        },
        {
            id: 1114,
            name: "Korea, Democratic People`s Republic of",
        },
        {
            id: 1115,
            name: "Korea, Republic of",
        },
        {
            id: 1116,
            name: "Kuwait",
        },
        {
            id: 1117,
            name: "Kyrgyzstan",
        },
        {
            id: 1118,
            name: "Lao People`s Democratic Republic",
        },
        {
            id: 1119,
            name: "Latvia",
        },
        {
            id: 1120,
            name: "Lebanon",
        },
        {
            id: 1121,
            name: "Lesotho",
        },
        {
            id: 1122,
            name: "Liberia",
        },
        {
            id: 1123,
            name: "Libyan Arab Jamahiriya",
        },
        {
            id: 1124,
            name: "Liechtenstein",
        },
        {
            id: 1125,
            name: "Lithuania",
        },
        {
            id: 1126,
            name: "Luxembourg",
        },
        {
            id: 1127,
            name: "Macao",
        },
        {
            id: 1128,
            name: "Macedonia, Republic of",
        },
        {
            id: 1129,
            name: "Madagascar",
        },
        {
            id: 1130,
            name: "Malawi",
        },
        {
            id: 1131,
            name: "Malaysia",
        },
        {
            id: 1132,
            name: "Maldives",
        },
        {
            id: 1133,
            name: "Mali",
        },
        {
            id: 1134,
            name: "Malta",
        },
        {
            id: 1135,
            name: "Marshall Islands",
        },
        {
            id: 1136,
            name: "Martinique",
        },
        {
            id: 1137,
            name: "Mauritania",
        },
        {
            id: 1138,
            name: "Mauritius",
        },
        {
            id: 1139,
            name: "Mayotte",
        },
        {
            id: 1140,
            name: "Mexico",
        },
        {
            id: 1141,
            name: "Micronesia, Federated States of",
        },
        {
            id: 1142,
            name: "Moldova",
        },
        {
            id: 1143,
            name: "Monaco",
        },
        {
            id: 1144,
            name: "Mongolia",
        },
        {
            id: 1145,
            name: "Montserrat",
        },
        {
            id: 1146,
            name: "Morocco",
        },
        {
            id: 1147,
            name: "Mozambique",
        },
        {
            id: 1148,
            name: "Namibia",
        },
        {
            id: 1149,
            name: "Nauru",
        },
        {
            id: 1150,
            name: "Nepal",
        },
        {
            id: 1151,
            name: "Netherlands Antilles",
        },
        {
            id: 1152,
            name: "Netherlands",
        },
        {
            id: 1153,
            name: "New Caledonia",
        },
        {
            id: 1154,
            name: "New Zealand",
        },
        {
            id: 1155,
            name: "Nicaragua",
        },
        {
            id: 1156,
            name: "Niger",
        },
        {
            id: 1157,
            name: "Nigeria",
        },
        {
            id: 1158,
            name: "Niue",
        },
        {
            id: 1159,
            name: "Norfolk Island",
        },
        {
            id: 1160,
            name: "Northern Mariana Islands",
        },
        {
            id: 1161,
            name: "Norway",
        },
        {
            id: 1162,
            name: "Oman",
        },
        {
            id: 1163,
            name: "Pakistan",
        },
        {
            id: 1164,
            name: "Palau",
        },
        {
            id: 1165,
            name: "Palestinian Territory, Occupied",
        },
        {
            id: 1166,
            name: "Panama",
        },
        {
            id: 1167,
            name: "Papua New Guinea",
        },
        {
            id: 1168,
            name: "Paraguay",
        },
        {
            id: 1169,
            name: "Peru",
        },
        {
            id: 1170,
            name: "Philippines",
        },
        {
            id: 1171,
            name: "Pitcairn",
        },
        {
            id: 1172,
            name: "Poland",
        },
        {
            id: 1173,
            name: "Portugal",
        },
        {
            id: 1174,
            name: "Puerto Rico",
        },
        {
            id: 1175,
            name: "Qatar",
        },
        {
            id: 1176,
            name: "Romania",
        },
        {
            id: 1177,
            name: "Russian Federation",
        },
        {
            id: 1178,
            name: "Rwanda",
        },
        {
            id: 1179,
            name: "Reunion",
        },
        {
            id: 1180,
            name: "Saint Helena",
        },
        {
            id: 1181,
            name: "Saint Kitts and Nevis",
        },
        {
            id: 1182,
            name: "Saint Lucia",
        },
        {
            id: 1183,
            name: "Saint Pierre and Miquelon",
        },
        {
            id: 1184,
            name: "Saint Vincent and the Grenadines",
        },
        {
            id: 1185,
            name: "Samoa",
        },
        {
            id: 1186,
            name: "San Marino",
        },
        {
            id: 1187,
            name: "Saudi Arabia",
        },
        {
            id: 1188,
            name: "Senegal",
        },
        {
            id: 1189,
            name: "Seychelles",
        },
        {
            id: 1190,
            name: "Sierra Leone",
        },
        {
            id: 1191,
            name: "Singapore",
        },
        {
            id: 1192,
            name: "Slovakia",
        },
        {
            id: 1193,
            name: "Slovenia",
        },
        {
            id: 1194,
            name: "Solomon Islands",
        },
        {
            id: 1195,
            name: "Somalia",
        },
        {
            id: 1196,
            name: "South Africa",
        },
        {
            id: 1197,
            name: "South Georgia and the South Sandwich Islands",
        },
        {
            id: 1198,
            name: "Spain",
        },
        {
            id: 1199,
            name: "Sri Lanka",
        },
        {
            id: 1200,
            name: "Sudan",
        },
        {
            id: 1201,
            name: "Suriname",
        },
        {
            id: 1202,
            name: "Svalbard and Jan Mayen",
        },
        {
            id: 1203,
            name: "Swaziland",
        },
        {
            id: 1204,
            name: "Sweden",
        },
        {
            id: 1205,
            name: "Switzerland",
        },
        {
            id: 1206,
            name: "Syrian Arab Republic",
        },
        {
            id: 1207,
            name: "Sao Tome and Principe",
        },
        {
            id: 1208,
            name: "Taiwan",
        },
        {
            id: 1209,
            name: "Tajikistan",
        },
        {
            id: 1210,
            name: "Tanzania, United Republic of",
        },
        {
            id: 1211,
            name: "Thailand",
        },
        {
            id: 1212,
            name: "Bahamas",
        },
        {
            id: 1213,
            name: "Gambia",
        },
        {
            id: 1214,
            name: "Togo",
        },
        {
            id: 1215,
            name: "Tokelau",
        },
        {
            id: 1216,
            name: "Tonga",
        },
        {
            id: 1217,
            name: "Trinidad and Tobago",
        },
        {
            id: 1218,
            name: "Tunisia",
        },
        {
            id: 1219,
            name: "Turkey",
        },
        {
            id: 1220,
            name: "Turkmenistan",
        },
        {
            id: 1221,
            name: "Turks and Caicos Islands",
        },
        {
            id: 1222,
            name: "Tuvalu",
        },
        {
            id: 1223,
            name: "Uganda",
        },
        {
            id: 1224,
            name: "Ukraine",
        },
        {
            id: 1225,
            name: "United Arab Emirates",
        },
        {
            id: 1226,
            name: "United Kingdom",
        },
        {
            id: 1227,
            name: "United States Minor Outlying Islands",
        },
        {
            id: 1228,
            name: "United States",
        },
        {
            id: 1229,
            name: "Uruguay",
        },
        {
            id: 1230,
            name: "Uzbekistan",
        },
        {
            id: 1231,
            name: "Vanuatu",
        },
        {
            id: 1232,
            name: "Venezuela",
        },
        {
            id: 1233,
            name: "Viet Nam",
        },
        {
            id: 1234,
            name: "Virgin Islands, U.S.",
        },
        {
            id: 1235,
            name: "Wallis and Futuna",
        },
        {
            id: 1236,
            name: "Western Sahara",
        },
        {
            id: 1237,
            name: "Yemen",
        },
        {
            id: 1238,
            name: "Serbia and Montenegro",
        },
        {
            id: 1239,
            name: "Zambia",
        },
        {
            id: 1240,
            name: "Zimbabwe",
        },
        {
            id: 1241,
            name: "Åland Islands",
        },
        {
            id: 1242,
            name: "Serbia",
        },
        {
            id: 1243,
            name: "Montenegro",
        },
        {
            id: 1244,
            name: "Jersey",
        },
        {
            id: 1245,
            name: "Guernsey",
        },
        {
            id: 1246,
            name: "Isle of Man",
        },
    ]);

    useEffect(() => {
        const fetchData = async () => {
            if (inputValues && inputValues.country) {
                const filterData = [
                    {
                        fieldName: "CountryId",
                        operatorName: "equal",
                        compareValue: inputValues.country,
                    },
                ];

                try {
                    const data = await StateInfoApi.GetAllStateWithFilter(filterData);
                    setStateInfo(data);
                } catch (error) {
                    console.log(error);
                }
            }
        };
        fetchData();
    }, [EditData, inputValues.country]);

    const validateInput = (fieldName) => {
        // console.log(fieldName);
        const fieldValue = inputValues[fieldName];

        if (
            !fieldValue ||
            (typeof fieldValue === "string" && fieldValue.trim() === "")
        ) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                [fieldName]: `${toFriendlyName(fieldName)} field is required`,
            }));
            return false;
        }
    };
    const fieldNames = [];

    const toFriendlyName = (fieldName) => {
        return fieldName
            .replace(/([A-Z])/g, " $1")
            .replace(/^./, (str) => str.toUpperCase());
    };


    const inputRef1 = useRef(null);
    const inputRef2 = useRef(null);
    const inputRef3 = useRef(null);
    const inputRef4 = useRef(null);

    const focusInput = (inputRef) => {
        if (inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    };

    const SaveBtn = async () => {
        debugger
        // console.log(props.data);
        let allValid = true;
        fieldNames.forEach((fieldName) => {
            const isValid = validateInput(fieldName);

            if (!isValid) {
                allValid = false;
            }
        });

        if (addresses.length === 0 && inputValues.name =="" && inputValues.address == "") {
            alert("Please add an address before proceeding.");
            setIsModalOpenAddAddress(true);
            setShowSelectAddress(false);
            return;
        }
    
        // Ensure at least one address is selected
        const isAddressSelected = addresses.some(address => address.isDefault);
        if (!isAddressSelected  && inputValues.name =="" && inputValues.address == "") {
            alert("Please select an address before proceeding.");
            return;
        }

        if (showSelectAddress === true) {    
            navigate('/PaymentMethods');
            return;
        }

        if (allValid) {
            if (EditData) {
                if (inputValues.name === "") {
                    alert("Name field is Required")
                    focusInput(inputRef1)
                }
                else if (inputValues.address === "") {
                    alert("Address field is Required ")
                    focusInput(inputRef2)
                }
                else if (inputValues.state === "" && inputValues.state === 0) {
                    alert("Please select the state ")
                    focusInput(inputRef3)
                }
                else if (inputValues.city === "") {
                    alert("City/District feild is required ")
                    focusInput(inputRef4)
                }
                else {
                    const address = await AddAddress.updateAddress(
                        inputValues,
                        myAccountID
                    );
                    if (address) {
                        alert("Address Updated successfully");
                        // setEditData(address);
                        navigate('/PaymentMethods');

                    } else {
                        alert("error while updating");
                    }
                }
            } else {
                try {
                    if (inputValues.name === "") {
                        alert("Name field is Required")
                        focusInput(inputRef1)
                    }
                    else if (inputValues.address === "") {
                        alert("Address field is Required ")
                        focusInput(inputRef2)
                    }
                    else if (inputValues.state === "" || inputValues.state === 0) {
                        alert("Please select the state ")
                        focusInput(inputRef3)
                    }
                    else if (inputValues.city === "") {
                        alert("City/District feild is required ")
                        focusInput(inputRef4)
                    }
                    else {
                        const address = await AddAddress.insertAddress(
                            inputValues,
                            myAccountID
                        );

                        if (address) {
                            alert("Address added successfully");
                            // props.modalclose();
                            // props.fetchAddress();
                        
                            navigate('/PaymentMethods');
                        } else {
                            // Handle case when address is not added successfully
                        }
                    }
                } catch (error) {
                    console.error("Error inserting data:", error);
                    alert("Error inserting data");
                }
            }
        } else {
            alert("Validation failed");
        }
    };

    //******************************************************* */

    const fetchAddress = async () => {
        var loginDataString = localStorage.getItem("accountLogin");
        var loginData = JSON.parse(loginDataString);
        console.log(loginData.d1Id);
        setMyAccountID(loginData.d1Id);
        var addresses = await AddAddress.getAddress(loginData.d1Id);
        if (addresses) {
            setAddAddresses(addresses);
            //   setAddressModal(false);
            // setIsModalOpenAddAddress(true);
            setLoading(false);
        } else {
            alert("Error Occoured");
        }
    };

    useEffect(() => {
        const fetchAddress = async () => {
            var loginDataString = localStorage.getItem("accountLogin");
            var loginData = JSON.parse(loginDataString);
            console.log(loginData.d1Id);
            setMyAccountID(loginData.d1Id);
            var addresses = await AddAddress.getAddress(loginData.d1Id);
            if (addresses) {
                setAddAddresses(addresses);
                // setAddressModal(false);
                // setIsModalOpenAddAddress(true);
                setLoading(false);
            } else {
                alert("Error Occoured");
            }
        };

        fetchAddress();
    }, []);

    console.log(addresses);

    //************************Edit Address********************* */

    const editaddress = async (id) => {
        var getaddress = await AddAddress.getAddressById(id, myAccountID);
        // console.log(data);
        console.log(getaddress);
        setEditData(getaddress);
        setIsModalOpenAddAddress(true);
        setShowSelectAddress(false);
    };

    //***************************Delete Address******************************* */

    const deleteaddress = async (id) => {
        if (window.confirm("Are you sure you want to delete this address?")) {
            setLoading(true);
            try {
                var deletedata = await AddAddress.deleteAddress(id, myAccountID);
                if (deletedata) {
                    alert("Address Deleted");
                    fetchAddress();
                } else {
                    alert("Error Occurred");
                }
            } catch (error) {
                alert("Error Occurred: " + error.message);
            } finally {
                setLoading(false);
            }
        }
    };


    useEffect(() => {
        setpathname(location.pathname)
    }, [location.pathname])


    return (
        <>  {InputValues.length === 0 ? (
            <div className="black-codesdf">
                <div className="p-graph-head-title">Your shopping cart is empty! </div>
                <Link to="/" className="Blank-cart-page-btn"><button className="pricebox-type-btn-theme3">Continue Shopping</button></Link>
            </div>
        ) : (
            <div>

                <div className="cartpage-high-theme3">
                    <div className="cartpage-left-theme3">
                        <div className="flex-Shipping-address">
                            {
                                navigationBar.map((item) => {
                                    return (
                                        <>
                                            <div onClick={() => !item.disable && item.name != "Payment" ? navigate(item.navigationroute) : null} className={` ${window.location.pathname == item.navigationroute ? "add-color-Shipping-add" : "Top_font-Shipping-Add"} ${item.disable && "disable-Shipping-add"}`}>{item.name}</div>
                                            <div><i class="fa-solid fa-chevron-right icon-margin-Rarrow"></i></div>
                                        </>
                                    )
                                })
                            }
                        </div>
                        {showSelectAddress && (
                            <div>
                                <div className="Save-Address-theme3 Select-Address-shipp">Select Address</div>
                                <div className="shipping-Address-flex">
                                    <div className="Flex-Checkout-powers">
                                        <p className="Paragraph-checkout-Font">If an address is available, select the existing address; if no address is available, create a new one to proceed</p>
                                    </div>
                                </div>
                                <div className="Save-Address-theme3 Saved-Add-shipp">Saved Addresses</div>
                                {addresses && addresses.length > 0 ? (
                                    addresses.map((address, index) => (
                                        <div className="shipping-Address-flex">
                                            <div className="Border-Saved-Address-Lc">
                                                <div className="Flex-Shipping-Saved">
                                                    <div className="Shipping-Saved-Home">

                                                        {address.typeOfAddress === 0 && (
                                                            <i className="fa-solid fa-house Home-icon-Lc"></i>
                                                        )}
                                                        {address.typeOfAddress === 1 && (
                                                            <i className="fa-solid fa-briefcase iconHouse-right-Lc"></i>
                                                        )}
                                                        {address.typeOfAddress === 2 && (
                                                            <i className="fa-solid fa-location-dot iconHouse-right-Lc"></i>
                                                        )}
                                                        {/* {address.typeOfAddress} */}
                                                        {address.typeOfAddress === 0 && "Home"}
                                                        {address.typeOfAddress === 1 && "Work"}
                                                        {address.typeOfAddress === 2 && "Others"}
                                                    </div>
                                                    <div className="" key={index} onClick={() => handleCheckboxChange(index, address.id)}>
                                                        <input className="CheckBox-input-click"
                                                            type="checkbox"
                                                            name="addressCheckbox"
                                                            // checked={selectedAddress === index}
                                                            checked={address.isDefault}
                                                            // onChange={() => handleCheckboxChange(index, address.id)}
                                                            value={address.isDefault}

                                                        />
                                                        <span className="checkmark"></span>
                                                    </div>
                                                </div>
                                                <div className="Flex-Shipping-Saved">
                                                    <div className="Address-font-shipping">
                                                        {/* {address.address},{address.locality},{address.city},
                                                        {stateInfo.filter(item => item.id === address.state).map(data => (
                                                            data.stateName
                                                        ))}
                                                        ,{address.pincode},{address.mobile} */}
                                                        {address.address}, {address.locality}, {address.city},
                                                        {/* {stateInfo && stateInfo.length > 0 ? (
                                                            stateInfo.filter(item => item.id === address.state).map(data => data.stateName).join('')
                                                        ) : 'State Not Found'},  */}
                                                        {address.pincode}, {address.mobile}
                                                    </div>
                                                    <div className="changes-Btn-Lc">
                                                        <button className="Delete-Btn-lc" onClick={() => deleteaddress(address.id)}>Delete</button>
                                                        <button className="Delete-Btn-lc" onClick={() => editaddress(address.id)}>Edit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div></div>
                                )}
                                <div className="shipping-Address-flex" onClick={handleOpenModalAddAddress}>
                                    <div className="Flex-Add-New-Lc">
                                        <div className="New-Address-shipp">Add New Address</div>
                                        <div><i class="fa-solid fa-arrow-right right-arrow-font"></i></div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {isModalOpenAddAddress && (
                            <div>
                                <div className="Save-Address-theme3">
                                    <div className="Arrow-Margin-right" onClick={handleGoBack}><i class="fa-solid fa-arrow-left"></i></div>
                                    <div className="Saved-Add-shipp">Save Address</div>
                                </div>
                                <div className="shipping-Address-flex">
                                    <div className="Flex-input-shipping">
                                        <div className="Input-Box-shipping-Lc">
                                            <input className="Input-shipping-name"
                                                type="text"
                                                onChange={handleInputChange}
                                                value={inputValues.name}
                                                name="name"
                                                placeholder="Full Name"
                                                ref={inputRef1} />
                                            {/* <label className="modify_p_text7">First Name</label> */}
                                        </div>
                                        <div className="Input-Box-shipping-Lc">
                                            <input className="Input-shipping-name"
                                                type="number"
                                                onChange={handleInputChange}
                                                value={inputValues.mobile}
                                                name="mobile"
                                                placeholder="Mobile No" />
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="Save-Address-theme3 Saved-Add-shipp">Select Gender</div> */}
                                {/* <div className="Flex-Gender-Shipping">
                                    <div className="gender-flex-add">
                                        <input className="input-radio-shipping" type="radio" name="gender" />
                                        <label className="Label-Gender-Lc">Male</label>
                                    </div>
                                    <div className="gender-flex-add">
                                        <input className="input-radio-shipping" type="radio" name="gender" />
                                        <label className="Label-Gender-Lc">Female</label>
                                    </div>
                                    <div className="gender-flex-add">
                                        <input className="input-radio-shipping" type="radio" name="gender" />
                                        <label className="Label-Gender-Lc">Prefer not to say</label>
                                    </div>
                                </div> */}
                                    <div className="shipping-Address-flex">
                                        <div className="Flex-input-shipping">
                                            <div className="Input-Box-shipping-Lc">
                                                <select
                                                    className="Input-shipping-name"
                                                    type="text"
                                                    onChange={handleInputChange}
                                                    value={inputValues.country}
                                                    name="country"
                                                >
                                                    <option>--Select Country--</option>
                                                    {(() => {
                                                        if (country !== null && Array.isArray(country)) {
                                                            return country.map((data) => (
                                                                <option key={data.id} value={data.id}>
                                                                    {data.name}
                                                                </option>
                                                            ));
                                                        }
                                                    })()}
                                                </select>
                                            </div>
                                            <div className="Input-Box-shipping-Lc">
                                                <select
                                                    className="Input-shipping-name"
                                                    type="text"
                                                    onChange={handleInputChange}
                                                    value={inputValues.state}
                                                    name="state"
                                                    ref={inputRef3}
                                                >
                                                    <option>--Select State--</option>
                                                    {(() => {
                                                        if (
                                                            stateInfo !== null &&
                                                            Array.isArray(stateInfo)
                                                        ) {
                                                            return stateInfo.map((data) => (
                                                                <option key={data.id} value={data.id}>
                                                                    {data.stateName}
                                                                </option>
                                                            ));
                                                        }
                                                    })()}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="shipping-Address-flex">
                                        <div className="Flex-input-shipping">
                                            <div className="Input-Box-shipping-Lc">
                                                <input
                                                    className="Input-shipping-name"
                                                    type="number"
                                                    onChange={handleInputChange}
                                                    value={inputValues.pincode}
                                                    name="pincode"
                                                    placeholder="Pincode"
                                                />
                                            </div>
                                            <div className="Input-Box-shipping-Lc">
                                                <input
                                                    className="Input-shipping-name"
                                                    type="text"
                                                    onChange={handleInputChange}
                                                    value={inputValues.address}
                                                    name="address"
                                                    placeholder="Address Line 1"
                                                    ref={inputRef2}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="shipping-Address-flex">
                                        <div className="Flex-Input-Address-Lc">
                                            <div className="Input-Box-shipping-Lc">
                                                <input
                                                    className="Input-shipping-name"
                                                    type="text"
                                                    onChange={handleInputChange}
                                                    value={inputValues.locality}
                                                    name="locality"
                                                    placeholder="Locality/Town"
                                                />
                                            </div>
                                            <div className="Input-Box-shipping-Lc">
                                                <input
                                                    className="Input-shipping-name"
                                                    type="text"
                                                    onChange={handleInputChange}
                                                    value={inputValues.city}
                                                    name="city"
                                                    placeholder="City/District"
                                                    ref={inputRef4}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="shipping-Address-flex">
                                        <div className="Flex-Checkout-powers">
                                            <p className="Paragraph-checkout-Font">
                                                Power will be taken after checkout. No additional
                                                charges for high powers.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="Save-Address-theme3 Saved-Add-shipp">
                                        Save as
                                    </div>
                                    <div className="shipping-Address-flex">
                                        <div className="Flex-Home-Work-Other">
                                            <div
                                                className={`Home-Icon-Box-Lc ${inputValues.typeOfAddress === 0 ? "active" : ""
                                                    }`}
                                                onClick={() => handleInputChange1(0)}
                                                // checked={inputValues.typeOfAddress === 0 ? 'active' : ''}
                                                name="typeOfAddress"
                                                id="home"
                                            >
                                                <i class="fa-solid fa-house iconHouse-right-Lc"></i>
                                                Home
                                            </div>
                                            <div
                                                className={`Home-Icon-Box-Lc ${inputValues.typeOfAddress === 1 ? "active" : ""
                                                    }`}
                                                onClick={() => handleInputChange1(1)}
                                                // checked={inputValues.typeOfAddress === 1 ? 'active' : ''}
                                                name="typeOfAddress"
                                                id="work"
                                            >
                                                <i class="fa-solid fa-briefcase iconHouse-right-Lc"></i>
                                                Work
                                            </div>
                                            <div
                                                className={`Home-Icon-Box-Lc ${inputValues.typeOfAddress === 2 ? "active" : ""
                                                    }`}
                                                onClick={() => handleInputChange1(2)}
                                                // checked={inputValues.typeOfAddress === 2 ? 'active' : ''}
                                                name="typeOfAddress"
                                                id="others"
                                            >
                                                <i class="fa-solid fa-location-dot iconHouse-right-Lc"></i>
                                                Other
                                            </div>
                                        </div>
                                    </div>
                                    <div className="shipping-Address-flex">
                                        <div className="addaddres-bottom_box width-Default-Address">
                                            <input
                                                style={{ marginRight: "8px" }}
                                                type="checkbox"
                                                onChange={handleInputChange2}
                                                checked={inputValues.isDefault === true}
                                                name="typeOfAddress"
                                                id=""
                                            />
                                            <label
                                                className="addaddress_lable"
                                                style={{ marginBottom: "0", fontSize: "13px" }}
                                                htmlFor=""
                                            >
                                                Make this as a Defult Address
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="cartpage-right-theme3">
                            <div
                                style={{ marginLeft: "5px" }}
                                className="cartpage-heading-theme3"
                            >
                                Bill Details
                            </div>

                        <div className="">
                            <div className="cartpage-pricebox">

                                <div className="pricebox-type-1">
                                    <div className="pricebox-heading">
                                        Item Total
                                    </div>
                                    <div className="pricebox-heading-2">
                                    {companyData.currencySymbol} {totalPrice}
                                    </div>
                                </div>
                                <div style={{ color: "rgb(17, 186, 216)" }} className="pricebox-type-1">
                                    <div className="pricebox-heading">
                                        Item Coupon
                                    </div>
                                    <div className="pricebox-heading-2">
                                    {companyData.currencySymbol} 0
                                    </div>
                                </div>
                                <div className="pricebox-type-1">
                                    <div className="pricebox-heading">
                                        Net Amount
                                    </div>
                                    <div className="pricebox-heading-2">
                                    {companyData.currencySymbol} {totalPrice}
                                    </div>
                                </div>
                                <div style={{ border: '0' }} className="pricebox-type-1">
                                    <div className="pricebox-heading">
                                        Total payable (incl. taxes)
                                    </div>
                                    <div className="pricebox-heading-2">
                                    {companyData.currencySymbol} {totalPrice}
                                    </div>
                                </div>
                            </div>
                            <div style={{ cursor: "pointer" }} onClick={OpenAddCoupon} className="cartpage-pricebox">
                                <div className="pricebox-1">
                                    <div style={{ display: "flex" }}>
                                        <img src={coupon} alt="" />
                                        <div className="pricebox-coupon">Add Coupon Code</div>
                                    </div>
                                    <i className="fa-solid fa-caret-right"></i>
                                </div>
                            </div>
                            <div

                                className={`navbar-option ${activeLink === "/MyAccount" ? "active" : ""
                                    }`}
                            >
                                <button className="pricebox-type-btn-theme3" onClick={SaveBtn}>Proceed To Payment</button>
                            </div>
                        </div>
                    </div>
                    {/* {modal && (
                        <Modal handleClick={signin} />
                    )} */}
                    {/* {alert && (<AlertBox alertname="Remove Item From Cart" close={() => DeleteDeleteEcomCartDetail()} />)} */}
                    {addcoupon && (<AddCouponModal handleClick={OpenAddCoupon} />)}
                    {/* <AlertSuccess /> */}
                </div>
                <WhatsAppButton/>
            </div>
        )}

        </>
    );
};

export default ShippingAddress;
