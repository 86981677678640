// NavBarMobile1.js
import React, { useContext, useState, useEffect } from "react";
import SideBar1 from "../SidebarMobile/SideBar1";
import Modal from "../Modal/Modal";
import { Link } from "react-router-dom";
import SearchPageMobile from "../Modal/SearchPageMobile";
import Cart from "../../Assets/Icons/shopping-bag.png"
import MyContext from "../../MyContext";
import LoginPanel_Lc from "../Modal/LoginPanel_Lc";
import SidebarMobile_Lc from "../SidebarMobile/SidebarMobile_Lc";

const NavBarMobile_Lc = (props) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const { imageBaseUrl, companyData, setLoginPanel, setwishLisHandle, wishLisHandle, modal, setModal, myaccountmodal } = useContext(MyContext);
  // const [modal, setModal] = useState(false);
  const [searchPanel, setSearchPanel] = useState(false);

  const { wishList } = props

  // console.log(wishList);

  const handleCheckbox = () => {
    setShowSidebar(!showSidebar);
  };


  const [userData, setUserData] = useState([]);
  // const [userCompany, setUserCompany] = useState([]);
  useEffect(() => {
    const data = localStorage.getItem("accountLogin");
    const parsedData = JSON.parse(data);
    setUserData(parsedData);
  }, []);

  const openmodal = () => {
    setModal(!modal);
  };
  const signin = () => {
    // debugger;
    setLoginPanel(!modal);
  };

  const openSearchpanel = () => {
    setSearchPanel(!searchPanel);
  };

  return (
    <>
      <div className="mobile-nav-spacing" style={{ height: "60px" }}></div>

      <div className="nav-bar-mobile">
        <div className="searh-position-res" onClick={openSearchpanel}>
          <input className="input-search-home" type="" placeholder="Search for Products" />
          <div className="searching-icon-pos">
            <i
              className="fa-solid fa-magnifying-glass "
            ></i>
          </div>
        </div>

        <div>
          <label className="menuButton" htmlFor="check">
            <input
              type="checkbox"
              checked={showSidebar}
              id="check"
              onClick={handleCheckbox}
              style={{ display: "none", visibility: "hidden" }}
              readOnly
            />
            <span className="top"></span>
            <span className="mid"></span>
            <span className="bot"></span>
          </label>
        </div>

        <div className="">
          <Link
            to="/fav"
            className="fa-regular fa-heart mobile-nav-icon mbl-top-rel"
          >
            <span className="mbl-cart-pg2">{wishList?.length || 0} </span>
          </Link>

          <span onClick={myaccountmodal} >
            <i onClick={signin}
              className="fa-regular fa-user mobile-nav-icon"
            ></i>
          </span>

          {/* <img src={Cart} alt="" style={{width:"20px", marginTop:"5px"}} /> */}
        </div>
        <Link to="/" className="Logo_theme_3_box">
          <img className="logo_theme_3-secondary" src={`${imageBaseUrl}${companyData.companyImageId}`} alt="" />
          {/* <img className="logo_theme_3-secondary" src={Logo} alt="" /> */}
        </Link>
        {searchPanel && <SearchPageMobile close={openSearchpanel} />}
      </div>



      <SidebarMobile_Lc handleClick={handleCheckbox} showSidebar={showSidebar} />
      {modal && <LoginPanel_Lc handleClick={openmodal} />}
      {/* {modal && <LoginPanel_Lc 
        signupshow1={signupshow1}
            LoginAccount={LoginAccount}
            forgotPassword={forgotPassword}
            inputValuesForLogin={inputValuesForLogin}
            handleInputChangeLogin={handleInputChangeLogin}
             handleClick={openmodal} />} */}
    </>
  );
};

export default NavBarMobile_Lc;
