import React, { useContext, useEffect, useState } from 'react'
import img11 from "./../../Assets/Image_lc/LensImg/single_vision.webp"
import img22 from "./../../Assets/Image_lc/LensImg/bifocal.webp"
import img33 from "./../../Assets/Image_lc/LensImg/zero_power.webp"
import img44 from "./../../Assets/Image_lc/LensImg/tinted_sv.webp"
// import LensMasterSettAndFeatures from "./../../Assets/LensMasterSettAndFeatures"
// import LensMasterSettAndFeatures from "../../../API/API_SERVISES/Order"
import Order from '../../../API/API_SERVISES/Order'
// import ThemeModal1 from './ThemeModal1'
import { Link, useLocation } from 'react-router-dom'
import MyContext from '../../MyContext'

const ThemeModal = (props) => {

    const { companyData, imageBaseUrl } = useContext(MyContext);
    const location = useLocation();
    const { item } = location.state || {};
    console.log(item);
    const [apiData, setapiData] = useState([])
    console.log(apiData);

    const [apiDataFeature, setapiDataFeature] = useState([])
    const [featurePrice, setFeaturePrice] = useState([])
    const [lensName, setLensName] = useState([])
    const [lensCoating, setLensCoating] = useState([])
    const [lensCoatingName, setLensCoatingName] = useState([])
    const [lensId, setLensId] = useState();

    const fetchData1 = async () => {
        try {
            const val = await Order.getItemRelatedLinkedLensProdSetting(item.d2Id);
            console.log(val);
            setapiData(val);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {
        fetchData1();
    }, []);

    // const fetchData2 = async () => {
    //     try {
    //         const val = await Order.getLensMasterSettAndFeatures(item.d2Id)
    //         console.log(val);
    //         setapiDataFeature(val);
    //     } catch (error) {
    //         console.error("Error fetching data:", error);
    //     }
    // };
    // useEffect(() => {
    //     fetchData2();
    // }, []);


    useEffect(() => {
        console.log("Api data here", apiData.result)
        console.log("Api data feature", apiDataFeature)
    }, [apiDataFeature])

    const handlemodal = async (id, lensPrice, lensName) => {
        // debugger
        setFeaturePrice(lensPrice)
        setLensName(lensName)
        setLensId(id);
        var data = await featuredata(id)
        if (data === true) {
            await props.handleModal(id)
        } else {
            props.handlelastModal(id)
        }
    }

    const addtocart2 = async (id,) => {
        console.log(featurePrice, lensName)
        // debugger
        await props.AddLensInCart(id, featurePrice, lensName, "0", "", lensId);
    }

    const addtocart3 = async (id,) => {
        console.log(featurePrice, lensName)
        // console.log(lensCoating, lensCoatingName)
        await props.AddLensInCart(id, featurePrice, lensName, "0", "", lensId);
    }
    // const addtocart4 = async (id,) => {
    //     console.log(featurePrice, lensName)
    //     console.log(lensCoating, lensCoatingName)
    //     // debugger
    //     await props.AddLensInCart(id, featurePrice, lensName, lensCoating, lensCoatingName)
    // }

    const addtocart4 = async (id) => {
        console.log(featurePrice, lensName);
        console.log(lensCoating, lensCoatingName);

        if (lensCoating.length != 0 && lensCoatingName.length != 0) {
            await props.AddLensInCart(id, featurePrice, lensName, lensCoating, lensCoatingName, lensId);
        } else {
            await addtocart2(id);
        }
    }

    const featuredata = async (id) => {
        const data = apiData.result
            .filter(item => item.itemId === id)
            .map(item => item.addFeatures)
            .flat();
        console.log("Featuredata: ", data);
        setapiDataFeature(data);
        return data.length > 0

    }

    const [selectedFeature, setSelectedFeature] = useState([]);

    const handleFeatureClick = (id, feature, price) => {
        // debugger;
        setLensCoatingName(feature)
        setLensCoating(price)
        setLensId(id);
        setSelectedFeature(feature, price);
        console.log("retertwe", price)
        props.handlelastModal(feature.id);
    };

    const finalPrice = props.price + featurePrice;
    const featurefinalPrice = finalPrice + lensCoating;

    return (
        <div>
            {props.SingleLensModalBox && (
                <div className="buywithlens-content">
                    <div className="buywithlens-left">
                        <div style={{ overflowX: "hidden", overflowY: "scroll", height: "65vh", scrollbarWidth: "thin" }}>
                            {apiData.result && apiData.result.filter(dataitem => dataitem.lensType === `${props.lensType}`).map((dataitem, index) => (
                                <div className="buy-with-lens-option-slider-theme3"
                                    onClick={() => handlemodal(dataitem.itemId, dataitem.price, dataitem.companyTitle)}
                                    key={index}>

                                    {dataitem.lensPackageImageId != 0 && dataitem.lensPackageImageId != "" && (
                                        <div className="feature-image-coating">
                                            <img
                                                className="img-coating-features"
                                                src={`${imageBaseUrl}${dataitem.lensPackageImageId}`}
                                                alt=""
                                            />
                                        </div>
                                    )}

                                    <div style={{ width: "100%" }}>
                                        <div>
                                            <div>
                                                <p className="Anti-class-add">{dataitem.companyTitle}</p>
                                            </div>
                                            <div className='slide-mat-desc'>
                                                <div className='slide-mat-desc'>
                                                    <p className="" style={{ margin: '0', fontSize: "13px" }} dangerouslySetInnerHTML={{ __html: dataitem.fullDescription }} />
                                                </div>
                                            </div>
                                            <div className='slide-mat-free-gif'>
                                                <p>{dataitem.freeLens}</p>
                                            </div>
                                            <div className='slide-ofr-sectn-btn'>
                                                {/* {`Frame+Lens: Get it For ${companyData.currencySymbol}${item.salePrice} | Lens Old Price: ${companyData.currencySymbol}${dataitem.oldPrice} | Lens Offer : ${companyData.currencySymbol}${dataitem.price}`} */}
                                                {`Frame+Lens: Get it For ${companyData.currencySymbol}${(item.salePrice + dataitem.price).toFixed(2)}`}
                                            </div>
                                        </div>
                                    </div>
                                    <i className="fa-solid fa-chevron-right"></i>
                                </div>
                            ))}
                        </div>
                        <div className="cart-btn-price">
                            <div className="slide-head-bar-td-1 flex-subtotal-cart" >
                                {/* <div className="slide-call-head-bar"><i class="fa-solid fa-phone-volume"></i>&nbsp;&nbsp;Not sure what to select?&nbsp;&nbsp;&nbsp;&nbsp;
                                    <h4>{companyData.mobileNo}</h4>
                                </div> */}
                                <div className="top-subtotal">
                                    <div className="slide-sub-amount-bar">SubTotal (Frame)</div>
                                    <div className="slide-sub-amount-bar">{companyData.currencySymbol} {props.price}</div>
                                </div>
                                <div className='cart-button add-cart-top'>
                                    <Link to="/Cart3" onClick={() => addtocart2(item.d2Id)} className="slide-head-bar-td-1">
                                        <button className="slide-add-cart-btn">Add To Cart</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }

            {/* {apiDataFeature && apiDataFeature.length > 0 && props.featureData && (
                <>
                    <div className="buywithlens-content">
                        <div className="buywithlens-left">
                            <div style={{ height: "60vh" }}>
                                {apiDataFeature.map((Feature, index) => (
                                    <div
                                        className="buy-with-lens-option-slider-theme3"
                                        key={index}
                                        onClick={() => handleFeatureClick(Feature.itemId, Feature.features, Feature.price)}
                                    >
                                        {Feature.illustrationImage != 0 && Feature.illustrationImage != "" && (
                                            <div className="feature-image-coating">
                                                <img
                                                    className="img-coating-features"
                                                    src={`${imageBaseUrl}${Feature.illustrationImage}`}
                                                    alt=""
                                                />
                                            </div>
                                        )}
                                        <div style={{ width: "100%" }}>
                                            <div>
                                                <div>
                                                    <p className="Anti-class-add">{Feature.features}</p>
                                                </div>
                                                <div className='slide-mat-desc'>
                                                    <div className='slide-mat-desc'>
                                                        <p>{Feature.remarks}</p>
                                                    </div>
                                                </div>
                                                <div className='slide-mat-free-gif'>
                                                    <p>{Feature.freeLens}</p>
                                                </div>
                                                <div className='slide-ofr-sectn-btn'>
                                                    {`Coating: ${companyData.currencySymbol}${Feature.price}`}
                                                </div>
                                            </div>
                                        </div>
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </div>
                                ))}
                            </div>
                            <div className="cart-btn-price">
                                <div className="slide-head-bar-td-1 flex-subtotal-cart">
                                    <div className="top-subtotal">
                                        <div className="slide-sub-amount-bar">SubTotal (Frame)</div>
                                        <div className="slide-sub-amount-bar">
                                            {companyData.currencySymbol} {finalPrice}
                                        </div>
                                    </div>
                                    <div className='cart-button add-cart-top'>
                                        <Link to="/Cart3" onClick={() => addtocart3(item.d2Id)} className="slide-head-bar-td-1">
                                            <button className="slide-add-cart-btn">Add To Cart</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )} */}

            {apiDataFeature && apiDataFeature.length > 0 && props.featureData && !props.eyePower && (
                <>
                    <div className="buywithlens-content">
                        <div className="buywithlens-left">
                            <div style={{ height: "60vh" }}>
                                {/* {apiDataFeature.some(feature => feature.price > 0 || feature.price === 0) ? ( */}
                                {apiDataFeature.some(feature => feature.features && (feature.price > 0 || feature.price === 0)) ? (
                                    apiDataFeature.map((Feature, index) => {
                                        // if (Feature.price === 0) {
                                        //     return null;
                                        // }
                                        // if (Feature.price === undefined || Feature.price === "" || Feature.price === null) {
                                        //     return null;
                                        // }
                                        if (!Feature.features) {
                                            return null;
                                        }
                                        return (

                                            <div
                                                className="buy-with-lens-option-slider-theme3"
                                                key={index}
                                                onClick={() => handleFeatureClick(Feature.itemId, Feature.features, Feature.price)}
                                            >
                                                {Feature.illustrationImage != 0 && Feature.illustrationImage != "" && (
                                                    <div className="feature-image-coating">
                                                        <img
                                                            className="img-coating-features"
                                                            src={`${imageBaseUrl}${Feature.illustrationImage}`}
                                                            alt=""
                                                        />
                                                    </div>
                                                )}

                                                <div style={{ width: "100%" }}>
                                                    <div>
                                                        <div>
                                                            <p className="Anti-class-add">{Feature.features}</p>
                                                        </div>
                                                        {/* <div className="slide-mat-desc">
                                                            <p>{Feature.remarks}</p>
                                                        </div> */}
                                                        <div className="slide-mat-free-gif">
                                                            <p>{Feature.freeLens}</p>
                                                        </div>
                                                        <div className="slide-ofr-sectn-btn">
                                                            {`${Feature.remarks}: ${companyData.currencySymbol}${Feature.price}`}
                                                        </div>
                                                    </div>
                                                </div>
                                                <i className="fa-solid fa-chevron-right"></i>
                                            </div>
                                        );
                                    })
                                ) : (
                                    setTimeout(() => props.setEyePower(true), 10)
                                )}
                            </div>

                            <div className="cart-btn-price">
                                <div className="slide-head-bar-td-1 flex-subtotal-cart">
                                    <div className="top-subtotal">
                                        <div className="slide-sub-amount-bar">SubTotal (Frame)</div>
                                        <div className="slide-sub-amount-bar">
                                            {companyData.currencySymbol} {finalPrice}
                                        </div>
                                    </div>
                                    <div className="cart-button add-cart-top">
                                        <Link to="/Cart3" onClick={() => addtocart3(item.d2Id)} className="slide-head-bar-td-1">
                                            <button className="slide-add-cart-btn">Add To Cart</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}


            {props.eyePower && (
                <div className="buywithlens-content">
                    <div className="buywithlens-left">
                        {/* {apiDataFeature.addFeatures.map((Feature, index) => ( */}
                        <div class="AboutEyePowerContainer">
                            <div class="Heading">
                                <h1 className='MyEyePower'>What About My Eye Power?</h1>
                            </div>
                            <div class="SubHeadingContainer">
                                <div class="RowContainer">
                                    <svg width="1.5em" height="1.5em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="16" fill="#737397"></rect>
                                        <path d="M21.333 12 14 19.333 10.666 16" stroke="#fff" stroke-linecap="square">

                                        </path>
                                    </svg>
                                    <div class="SubHeading">You can submit your eye power after Payment step</div>
                                </div>
                                <div class="RowContainer">
                                    <svg width="1.5em" height="1.5em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="16" fill="#737397"></rect>
                                        <path d="M21.333 12 14 19.333 10.666 16" stroke="#fff" stroke-linecap="square">
                                        </path>
                                    </svg>
                                    <div class="SubHeading">Power can be submitted within 10 days of order placement
                                    </div>
                                </div>
                                {/* <div class="RowContainer">
                                    <svg width="1.5em" height="1.5em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="16" fill="#737397"></rect>
                                        <path d="M21.333 12 14 19.333 10.666 16" stroke="#fff" stroke-linecap="square">
                                        </path>
                                    </svg>
                                    <div class="SubHeading">No additional charges for high/complex power

                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="cart-btn-price">
                            <div className="slide-head-bar-td-1 flex-subtotal-cart" >
                                {/* <div className="slide-call-head-bar"><i class="fa-solid fa-phone-volume"></i>&nbsp;&nbsp;Not sure what to select?&nbsp;&nbsp;&nbsp;&nbsp;
                                    <h4>Call 1800-202-4444</h4>
                                </div> */}
                                <div className="top-subtotal">
                                    <div className="slide-sub-amount-bar">SubTotal (Frame)</div>
                                    <div className="slide-sub-amount-bar">{companyData.currencySymbol} {featurefinalPrice}</div>
                                </div>
                                <div className='cart-button add-cart-top'>
                                    <Link to="/Cart3" onClick={() => addtocart4(item.d2Id)} className="slide-head-bar-td-1">
                                        <button className="slide-add-cart-btn">Add To Cart</button>
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            )}




        </div>
    )
}

export default ThemeModal